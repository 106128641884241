<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="header-contact">
              <ul>
                <li>Benvenuti in fotodp</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 text-right">
            <ul class="header-dropdown">
              <li><i class="fa fa-phone" aria-hidden="true"></i> Info e assistenza &nbsp;&nbsp;</li>
              <li  [routerLink]="['/login']">
                <i class="fa fa-user" aria-hidden="true"></i> Login
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main-menu">
            <div class="menu-left">
              <div class="brand-logo">
                <a routerLink="/" id="headerlogo">
                  <img src="assets/fotodp/logo/logo_fotodp.png" class="img-fluid" alt="fotoDP">
                </a>
              </div>
            </div>
            <div class="menu-left pull-left">
              <div>
                <app-menu-top></app-menu-top>
              </div>
              <div>
               <app-settings></app-settings>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>