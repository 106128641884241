<!--footer section 
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>Scoprilo per primo!</h4>
								<p>Iscriviti alla newsletter</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form"
							action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
							target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
									placeholder="Inserisci la tua email">
							</div>
							<button type="submit" class="btn btn-solid">Iscriviti</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-contant">
						<div class="footer-logo">
							<img src="assets/fotodp/logo/logo_fotodp.png" alt="fotoDP" style="max-height: 20px; ">
						</div>
						<div class="sub-title">
							<div class="footer-contant">
								<ul class="contact-list">
									<li><i class="fa fa-map-marker"></i>via righini 05 - 48125 Ravenna</li>
									<li><i class="fa fa-phone"></i>+39 3386031713 </li>
									<li><i class="fa fa-envelope-o"></i><a>infofotodp@gmail.com</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Marchi</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li class="pt-1"><a href="javascript:void(0)">Nikon</a></li>
                                <li class="pt-1"><a href="javascript:void(0)">Canon</a></li>
                                <li class="pt-1"><a href="javascript:void(0)">Sony</a></li>
                            </ul>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} fotodp srl P.Iva: IT 02694770393</p>
					</div>
				</div>
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a><img src="assets/images/icon/visa.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/mastercard.png" alt=""></a>
							</li>
							<--!<li>
								<a><img src="assets/images/icon/paypal.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/american-express.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/discover.png" alt=""></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<--footer section end -->

<!--footer section -->
<footer [ngClass]="class">
    <div class="light-layout">
        <div class="container">
            <section class="section-b-space border-b">
                <div class="row footer-theme2">
                    <div class="col-lg-6">
                        <div class="footer-title footer-mobile-title">
                            <h4></h4>
                        </div>
                        <div class="footer-contant">
                            <div class="footer-logo" id="footerlogo">
                                <img src="assets/fotodp/logo/logo_fotodp.png" alt="fotoDP" style="max-height: 40px;">
                            </div>
								<ul class="contact-details">
									<li><i class="fa fa-map-marker"></i>&nbsp; via Righini 05 - 48125 Ravenna</li>
									<li><i class="fa fa-phone"></i>&nbsp; +39 3386031713 </li>
									<li><i class="fa fa-envelope-o"></i>&nbsp; <a>infofotodp@gmail.com</a></li>
								</ul>
						</div>
                    </div>
                    <div class="col-lg-6 subscribe-wrapper">
                        <div class="subscribe-block">
							<h4>Scoprilo per primo!</h4>
							<p>Iscriviti alla newsletter</p>
                            <form >
                                <div class="form-group">
									<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
									placeholder="Inserisci la tua email">
                                    <button type="submit" class="btn btn-solid">Iscriviti</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
   
    <div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} fotodp srl P.Iva: IT 02694770393</p>
					</div>
				</div>
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a><img src="assets/images/icon/visa.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/mastercard.png" alt=""></a>
							</li>
							<!--<li>
								<a><img src="assets/images/icon/paypal.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/american-express.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/discover.png" alt=""></a>
							</li>-->
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->