<!-- Categories start-->
<div class="container">
    <div class="row">
        <div class="col">
            <div class="section-title">
                <h5>I nostri prodotti</h5>
            </div>
            <div class="pagitation-owl">
                <owl-carousel-o [options]="CategorySliderConfig">
                    <ng-container *ngFor="let category of categories">
                        <ng-template carouselSlide>
                            <div class="category-block">
                                <a [routerLink]="['/shop/collection/left/sidebar']"
                                    [queryParams]="{ category: 'shoes'}">
                                    <div class="category-image">
                                        <img [src]="category.image" alt="">
                                        <!--class="w-auto"-->
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']"
                                        [queryParams]="{ category: 'shoes'}">
                                        <h5>{{ category.title }}</h5>
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>
<!-- categories End -->


<!-- slider tab  -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="theme-tab">
                    <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title2">
                        <ngb-tab *ngFor="let collection of productCollections">
                            <ng-template ngbTabTitle>{{collection}}</ng-template>
                            <ng-template ngbTabContent>
                                <div class="no-slider row">
                                    <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                                        <div class="product-box">
                                            <app-product-box-one [product]="product"
                                                [currency]="productService?.Currency">
                                            </app-product-box-one>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- slider tab end -->

<!-- Brands start-->
<div class="container">
    <div class="row">
        <div class="col">
            <div class="section-title">
                <h5>I nostri brand</h5>
            </div>
            <div class="slide-5">
                <div class="pagitation-owl">
                    <owl-carousel-o [options]="BrandSliderConfig">
                        <ng-container *ngFor="let brand of brands ">
                            <ng-template carouselSlide>
                                <div class="brand-block">
                                    <a [routerLink]="['/shop/collection/left/sidebar']"
                                        [queryParams]="{ category: 'shoes'}">
                                        <div class="brand-image">
                                            <img [src]="brand.image" alt="">
                                        </div>
                                    </a>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Brand End -->

<div style="padding-top: 100px;"></div>

<div  class="light-layout">
    <!-- Services -->
    <section class="banner-padding absolute-banner pb-0">
        <div class="container absolute-bg">
            <div class="service p-0 ">
                <app-services></app-services>
            </div>
        </div>
    </section>
    <!-- Services end -->
</div>